import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MealType, Order, Restaurant } from '../../common/types';
import Modal from '../Modal/Modal';
import RoundView from '../RoundView/RoundView';
import './SelectRoundModal.scss';

interface SelectRoundModalProps {
  restaurantId: number;
  restaurants: Restaurant[];
  mealType: MealType;
  order?: Order;
  onBookRound: (eatingRoundId: number, mealType: MealType) => void;
  onUpdateBooking: (orderId: number, eatingRoundId: number) => void;
  onDeleteOrder: (orderId: number) => void;
}

export default function SelectRoundModal(props: SelectRoundModalProps) {
  const {
    restaurantId,
    restaurants,
    mealType,
    onBookRound,
    onUpdateBooking,
    order,
    onDeleteOrder,
  } = props;
  const [eatingRoundId, setEatingRoundId] = useState<number>(
    order?.eatingRoundId,
  );
  const history = useHistory();

  const mealTypeForRound = order ? order.mealType : mealType;
  const restaurantIdForRound = order ? order.restaurantId : restaurantId;

  const restaurant = restaurants.find(
    (rest) => rest.id === restaurantIdForRound,
  );

  const closeModal = () => {
    history.goBack();
  };

  if (!restaurant) {
    return null;
  }

  return (
    <Modal onClose={closeModal}>
      <div className="select-round-modal">
        <div className="title">Choose a time</div>
        <div className="sub-title">
          Feel free to take your time eating, this is just your time for picking
          up food.
        </div>
        {restaurant.eatingRounds.map(
          (er) =>
            er.mealType === mealTypeForRound && (
              <RoundView
                key={er.id}
                eatingRound={er}
                selectedRoundId={eatingRoundId}
                onRoundSelected={() => setEatingRoundId(er.id)}
              />
            ),
        )}
        <button
          className="book-button main-button"
          type="button"
          onClick={() => {
            if (order) {
              onUpdateBooking(order.id, eatingRoundId);
            } else {
              onBookRound(eatingRoundId, mealTypeForRound);
            }
            closeModal();
          }}
          disabled={!eatingRoundId}
        >
          {order ? 'Edit Booking' : 'Book'}
        </button>
        {order && (
          <div
            className="delete-order"
            onClick={() => {
              onDeleteOrder(order.id);
              closeModal();
            }}
          >
            Delete Booking
          </div>
        )}
      </div>
    </Modal>
  );
}
