import React, { useEffect, useRef, useState } from 'react';
import './main.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Login from './pages/Login/Login';
import LoginService from './services/LoginService';
import config from './config';
import { User } from './common/types';
import AppContent from './components/AppContent/AppContent';

type UserState = User | undefined | null;

export default function App() {
  const [user, setUser] = useState<UserState>();

  const loginService = useRef<LoginService>(new LoginService(config.API_PATH));

  const getUser = async () => {
    try {
      const fetchedUser = await loginService.current.getUser();
      setUser(fetchedUser);
    } catch {
      setUser(null);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const onLogout = async () => {
    try {
      await loginService.current.logout();
      getUser();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Logout failed!');
    }
  };

  if (user === undefined) {
    return <div>attempting to authenticate...</div>;
  }

  if (user === null) {
    return (
      <Login
        attemptLogin={async () => {
          try {
            const loginUrl = await loginService.current.getLoginUrl();
            window.location.href = loginUrl;
          } catch {
            // eslint-disable-next-line no-console
            console.log('Failed to get login URL!');
          }
        }}
      />
    );
  }

  return (
    <Router>
      <AppContent onLogout={onLogout} user={user}/>
    </Router>
  );
}