import React from 'react';
import GoogleLogoSvg from '../../assets/google_logo.svg';
import './Login.scss';

type LoginProps = {
  attemptLogin: () => Promise<void>;
};

/**
 * View for Login
 */
export default function Login({ attemptLogin }: LoginProps) {
  return (
    <div className="login">
      <div className="login-page-cover" />
      <div className="title">Welcome!</div>
      <div className="sub-title">
        You’re probably hungry, huh.
        <br /> Let’s fix that
      </div>
      <button type="button" onClick={attemptLogin}>
        <GoogleLogoSvg className="google-icon" height="24px" />
        Continue with google
      </button>
    </div>
  );
}
