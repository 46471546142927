import React from 'react';
import {
  getLastStartTimeRoundPerMeal,
  isTimePassedGracePeriod,
} from '../../common/timeUtils';
import { MealType, Order, Restaurant } from '../../common/types';
import RestaurantCardView from '../RestaurantCardView/RestaurantCardView';
// When the restaurants will have the option for breakfast and dinner.
// The code will need to use TabbedView.
// import TabbedView, { Tab } from '../TabbedView/TabbedView';
import './Restaurants.scss';

interface RestaurantProps {
  restaurant: Restaurant;
  mealType: MealType;
  todayOrders: Order[];
}

function RestaurantView(props: RestaurantProps) {
  const { restaurant, mealType, todayOrders } = props;
  const { todayMenus, eatingRounds } = restaurant;
  const orderBooked = todayOrders.find((order) =>
    eatingRounds.find(
      (eatingRound) =>
        eatingRound.id === order.eatingRoundId &&
        eatingRound.mealType === mealType,
    ),
  );

  return (
    todayMenus[mealType] && (
      <RestaurantCardView
        key={restaurant.name}
        restaurant={restaurant}
        mealType={mealType}
        orderBooked={orderBooked}
      />
    )
  );
}

const getRestaurantsViewByMeal = (
  restaurants: Restaurant[],
  mealType: MealType,
  todayOrders: Order[],
) => {
  const isMenuForMealToday = restaurants.find(
    (rest) => rest.todayMenus[mealType],
  );

  if (!isMenuForMealToday) {
    return <div className="meal-massage">Sorry, no {mealType} today</div>;
  }

  const getIsMealFinished = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    const minStartTime = today;

    const lastStartTimeTodayForMeal = restaurants.reduce(
      (lastStartTimeToday, rest) => {
        const lastStartRoundTime = getLastStartTimeRoundPerMeal(rest, mealType);
        return lastStartRoundTime > lastStartTimeToday
          ? lastStartRoundTime
          : lastStartTimeToday;
      },
      minStartTime,
    );

    return isTimePassedGracePeriod(lastStartTimeTodayForMeal);
  };

  if (getIsMealFinished()) {
    return (
      <div className="meal-massage">
        {mealType} is finished
        <div className="meal-sub-massage">We will be back again tomorrow</div>
      </div>
    );
  }

  return restaurants.map((rest) => (
    <RestaurantView
      key={rest.id}
      restaurant={rest}
      mealType={mealType}
      todayOrders={todayOrders}
    />
  ));
};

interface RestaurantsProps {
  todayOrders: Order[];
  restaurants: Restaurant[];
}

/**
 * View for Restaurants
 */
export default function Restaurants(props: RestaurantsProps) {
  const { todayOrders, restaurants } = props;
  return (
    <div className="restaurants">
      {getRestaurantsViewByMeal(restaurants, 'Lunch', todayOrders)}
    </div>
  );
}
