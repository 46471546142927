import { useEffect, useState } from 'react';
import { MealType, OrderBase, Order, User } from '../../common/types';
import { notifyError, notifySuccess } from '../../common/notifyToast';
import DataService from '../../services/DataService';

interface IOrders {
  bookEatingRound: (eatingRoundId: number, mealType: MealType) => void;
  deleteBookEatingRound: (orderId: number) => void;
  updateBookEatingRound: (orderId: number, eatingRoundId: number) => void;
  todayOrders: Order[];
}

/** A hook to handle all orders logic */
export function useOrders(
  dataService: DataService,
  user: User,
  updateRestaurantsCallback: () => void,
): IOrders {
  const [orders, setOrders] = useState<Order[]>([]);
  const getOrders = async () => {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    dataService.getOrders(today, tomorrow).then((ordersRes) => {
      setOrders(ordersRes);
    });
  };

  useEffect(() => {
    if (user) {
      getOrders();
    }
  }, [user]);

  const todayOrders = orders.filter(
    (order) => order.date.toDateString() === new Date().toDateString(),
  );

  const addOrder = (newOrder: Order) => {
    setOrders((prevState) => [...prevState, newOrder]);
  };

  const deleteOrder = (orderIdToDelete: number) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.id !== orderIdToDelete),
    );
  };

  const updateOrder = (newOrder: Order) => {
    setOrders((prevState) =>
      prevState.map((order) => (order.id === newOrder.id ? newOrder : order)),
    );
  };

  const bookEatingRound = async (eatingRoundId: number, mealType: MealType) => {
    const existingOrderForMeal = todayOrders.find(
      (todayOrder) => todayOrder.mealType === mealType,
    );
    if (existingOrderForMeal) {
      notifyError(`You have an order for ${mealType}.
    please cancel it first.`);
      return;
    }

    const newOrder: OrderBase = {
      eatingRoundId,
      date: new Date(),
    };

    let response: Order;
    try {
      response = await dataService.addOrder(newOrder);
    } catch (err) {
      notifyError();
      return;
    }
    notifySuccess(`Your ${mealType} is Booked!`);
    addOrder(response);
    updateRestaurantsCallback();
  };

  const deleteBookEatingRound = async (orderId: number) => {
    try {
      await dataService.deleteOrder(orderId);
    } catch (err) {
      notifyError();
      return;
    }
    notifySuccess(`Your Booking has been deleted`);
    deleteOrder(orderId);
    updateRestaurantsCallback();
  };

  const updateBookEatingRound = async (
    orderId: number,
    eatingRoundId: number,
  ) => {
    const newOrder: OrderBase = {
      eatingRoundId,
      date: new Date(),
    };
    let response: Order;
    try {
      response = await dataService.updateOrder(orderId, newOrder);
    } catch (err) {
      notifyError();
      return;
    }
    notifySuccess(`Your Booking has been updated!`);
    updateOrder(response);
    updateRestaurantsCallback();
  };

  return {
    bookEatingRound,
    deleteBookEatingRound,
    updateBookEatingRound,
    todayOrders,
  };
}
