import React from 'react';
import { Menu, Restaurant } from '../../common/types';
import './MenuItemView.scss';

const RESTAURANT_COLORS = ['#ce4781', '#77a1fe', '#8a12ca', '#be9e14'];

type MenuItemViewProps = {
  restaurants: Restaurant[];
  menu: Menu;
  onEditMenu: (menu: Menu) => void;
};

export default function MenuItemView(props: MenuItemViewProps) {
  const { restaurants, menu, onEditMenu } = props;
  const restaurant = restaurants.find((r) => r.id === menu.restaurantId);
  return (
    <button
      type="button"
      className="daily-menu"
      style={{
        backgroundColor: RESTAURANT_COLORS[menu.restaurantId % 4],
      }}
      onClick={() => {
        onEditMenu(menu);
      }}
    >
      <div className="small-text">{menu.mealType}</div>
      {restaurant && <div className="large-text">{restaurant.name}</div>}
    </button>
  );
}
