import React from 'react';
import { Menu } from '../../common/types';
import './EmployeeMenuView.scss';

interface EmployeeMenuViewProps {
  lang: 'en' | 'he';
  menu: Menu;
}

/**
 * View for menu
 */
export default function EmployeeMenuView(props: EmployeeMenuViewProps) {
  const { lang, menu } = props;

  const displayDate = new Date(menu.date).toLocaleDateString('he-IL');
  const todaysDate = new Date().toLocaleDateString('he-IL');
  const idToday = displayDate === todaysDate;
  const isEnglish = lang === 'en';

  return (
    <div className="menu-view-container">
      <div className="date">{idToday ? 'Today' : displayDate}</div>
      {menu.dishes.map((dish) => {
        const { id, name, description, calories, type } = dish;
        return (
          <div
            className="dish-container"
            dir={isEnglish ? 'ltr' : 'rtl'}
            key={id}
          >
            <div className="details">
              <div className="name">{name[lang]}</div>
              <div className="description">{description[lang]}</div>
              {calories && (
                <div className="calories">{`${calories} ${
                  isEnglish ? 'Cal' : 'קל׳'
                }`}</div>
              )}
            </div>
            {type && <div className="type">{type}</div>}
          </div>
        );
      })}
    </div>
  );
}
