import React from 'react';
import CloseIconSvg from '../../assets/close_icon.svg';
import './Modal.scss';

type ModalProps = {
  children: React.ReactChild;
  onClose: () => void;
};

/** Renders a Modal (popup) */
export default function Modal(props: ModalProps) {
  const { children, onClose } = props;
  return (
    <div className="modal">
      <div className="modal-content">
        <button type="button" className="modal-close-button" onClick={onClose}>
          <CloseIconSvg className="close-icon" height="22px" />
        </button>
        {children}
      </div>
    </div>
  );
}
