import React from 'react';
import Modal from '../Modal/Modal';

import './ConfirmModal.scss';

type ConfirmModalProps = {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export default function ConfirmModal(props: ConfirmModalProps) {
  const { message, onConfirm, onCancel } = props;
  return (
    <Modal onClose={onCancel}>
      <div className="confirm-modal-top">
        <div className="confirm-modal-text">{message}</div>
        <div className="confirm-modal-buttons-container">
          <button
            onClick={onConfirm}
            type="button"
            className="modal-button confirm"
          >
            Confirm
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="modal-button cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
