import React from 'react';
import { Dish } from '../../common/types';

import './DishView.scss';

type DishViewProps = {
  onClick?: (dish: Dish) => void;
  dish: Dish;
};

export default function DishView(props: DishViewProps) {
  const { onClick, dish } = props;
  return (
    <div
      style={onClick ? { cursor: 'pointer' } : {}}
      onClick={onClick ? () => onClick(dish) : undefined}
      className="dish-item"
    >
      <div className="dish-name-text">
        {dish.name.en} | {dish.name.he}
      </div>
      <div dir="rtl" className="dish-description-text">
        {dish.description.he}
      </div>
      <div className="dish-description-text">{dish.description.en}</div>
    </div>
  );
}
