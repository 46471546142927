import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { User, FoodPref } from '../common/types';
import config from '../config';

const FoodPreferenceMapping: Record<string, FoodPref> = {
  VGT: 'Vegetarian',
  VGN: 'Vegan',
};

export default class LoginService {
  axiosClient: AxiosInstance;

  constructor(apiBaseUrl: string) {
    this.axiosClient = axios.create({
      baseURL: apiBaseUrl,
      headers: {
        // 'Access-Control-Allow-Origin': '*',
      },
    });
  }

  /** retrieves login url */
  async getLoginUrl(): Promise<string> {
    const response = await this.axiosClient.get('login/google/', {
      withCredentials: true,
    });
    return response.data.url;
  }

  /** returns current user data (or throws if no user is currently logged in) */
  async getUser(): Promise<User> {
    const response = await this.axiosClient.get('api/users/me/', {
      withCredentials: true,
    });
    const rawUser = response.data.data;
    return {
      email: rawUser.email,
      gender: rawUser.gender,
      id: rawUser.id,
      allergies: rawUser.allergies,
      firstName: rawUser.first_name,
      lastName: rawUser.last_name,
      isRestaurantWorker: rawUser.is_restaurant_worker,
      isFrontDeskWorker: rawUser.is_front_desk_worker,
      isTablet: rawUser.is_tablet,
      foodPreferences: rawUser.food_preferences.map((fp) => FoodPreferenceMapping[fp]),
    } as User;
  }

  /** attempts to logout */
  async logout(): Promise<void> {
    const csrftoken = Cookies.get(config.CSRF_TOKEN_COOKIE_NAME);
    const response = await this.axiosClient.post('logout/', undefined, {
      headers: {
        [config.CSRF_TOKEN_HEADER_NAME]: csrftoken,
      },
    });
    return response.data;
  }
}
