import React, { useContext, useState } from 'react';
import { Dish, MealType, Menu, Restaurant } from '../../common/types';
import 'react-toastify/dist/ReactToastify.css';
import {
  getDateString,
  getMonthDisplayName,
  getWeekdayName,
} from '../../common/dateUtils';

import ConfirmModal from '../ConfirmModal/ConfirmModal';
import DishesForMenuEditor from '../DishesForMenuEditor/DishesForMenuEditor';
import AddDishes from '../AddDishesMenu/AddDishes';

import './MenuEditor.scss';
import { DataServiceContext } from '../../common/contexts';
import {notifySuccess} from "../../common/notifyToast";

/** returns the first restaurant that doesn't have all menus for this date already */
function getFirstRestWithMissingMenus(
  restaurants: Restaurant[],
  menus: Menu[],
  date: Date,
) {
  return (
    restaurants.find((rest) => {
      const menusForRest = menus
        .filter((m) => m.restaurantId === rest.id)
        .filter((m) => getDateString(m.date) === getDateString(date));
      return menusForRest.length < 3;
    }).id ?? 1
  );
}

/**
 * returns the first meal that doesn't have a menu yet for the current
 * restaurant and day
 */
function getFirstMealWithoutMenu(
  meals: MealType[],
  restId: number,
  menus: Menu[],
  date: Date,
) {
  return (
    meals.find((mt) => {
      const menusForRest = menus
        .filter((m) => m.restaurantId === restId)
        .filter((m) => getDateString(m.date) === getDateString(date));
      return !menusForRest.map((m) => m.mealType).includes(mt);
    }) ?? 'Dinner'
  );
}

type NewMenu = {
  dishes: Dish[];
  restaurantId: number;
  date: Date;
  mealType: MealType;
};

type MenuEditorProps = {
  /** Menu from which the editor was opened (if opened by clicking existing menu) */
  initialMenu?: Menu;
  /** Date to create new menu (if opened by clicking the create new menu button) */
  dateForNewMenu?: Date;
  /** all menus available */
  allMenus: Menu[];
  /** all dishes available */
  allDishes: Dish[];
  /** all restaurants available */
  restaurants: Restaurant[];
  /** all restaurants available setter */
  setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>;
  mealTypes: MealType[];
  /** cb to call when data needs to be updated (a menu was changed/added) */
  update: () => void;
};

type MenuParamChange = {
  mealType: MealType;
  restaurantId: number;
};

/**
 * View for menu editor
 */
export default function MenuEditor(props: MenuEditorProps) {
  const {
    initialMenu,
    allMenus,
    restaurants,
    setRestaurants,
    mealTypes,
    dateForNewMenu,
    allDishes,
    update,
  } = props;
  const dataService = useContext(DataServiceContext);
  const [modified, setModified] = useState(false);
  const [currentRestId, setCurrentRestId] = useState(
    initialMenu?.restaurantId ?? restaurants[0].id,
  );
  const [currentMealType, setCurrentMealType] = useState(
    initialMenu?.mealType ?? 'Lunch',
  );
  const [currentMenu, setCurrentMenu] = useState<Menu | undefined>(initialMenu);
  const [menuParamChange, setMenuParamChange] = useState<MenuParamChange>();
  const [newMenu, setNewMenu] = useState<NewMenu | undefined>(() => {
    if (initialMenu) {
      return undefined;
    }
    const selectedRestId = getFirstRestWithMissingMenus(
      restaurants,
      allMenus,
      dateForNewMenu,
    );
    return {
      date: dateForNewMenu,
      dishes: [],
      restaurantId: selectedRestId,
      mealType: getFirstMealWithoutMenu(
        mealTypes,
        selectedRestId,
        allMenus,
        dateForNewMenu,
      ),
    };
  });

  const relevantMenu = currentMenu ?? newMenu;

  const updateRestaurantsOnSave = () => {
    if (relevantMenu.date.getDay() === new Date().getDay()) {
      const index = restaurants.findIndex((rest) => rest.id === relevantMenu.restaurantId)

      if (!restaurants[index].todayMenus[relevantMenu.mealType]) {
        const updatedRestaurants = [...restaurants]
        const updatedRestaurant = {...updatedRestaurants[index]}
        const todayMenus = {...updatedRestaurant.todayMenus}
        todayMenus[relevantMenu.mealType] = true
        updatedRestaurant.todayMenus = todayMenus
        updatedRestaurants[index] = updatedRestaurant

        setRestaurants(updatedRestaurants)
      }
    }
  }

  const onSave = async () => {
    if (currentMenu) {
      await dataService.updateMenu(currentMenu.id, currentMenu.dishes);
    } else if (newMenu) {
      await dataService.createMenu(
        relevantMenu.dishes,
        relevantMenu.restaurantId,
        relevantMenu.date,
        relevantMenu.mealType,
      );
    } else {
      throw new Error('Should update existing or new menu!');
    }
    const currentRest = restaurants.find((rest) => rest.id === relevantMenu.restaurantId)
    if (currentRest) {
      notifySuccess(`menu for ${relevantMenu.mealType} at
                        ${currentRest.name} on ${relevantMenu.date.toLocaleDateString()} saved`);

    }
    update();
    updateRestaurantsOnSave()
    setModified(false);
  };

  /** change restaurant of meal type for the editor (will discard changes) */
  const updateMenuAttributes = (newRestId: number, newMealType: MealType) => {
    setModified(false);
    const matchingMenu = allMenus.find(
      (m) =>
        getDateString(m.date) === getDateString(relevantMenu.date) &&
        m.mealType === newMealType &&
        m.restaurantId === newRestId,
    );

    if (matchingMenu) {
      setCurrentMenu(matchingMenu);
      setNewMenu(undefined);
    } else {
      setNewMenu({
        date: relevantMenu.date,
        mealType: newMealType,
        restaurantId: newRestId,
        dishes: [],
      });
      setCurrentMenu(undefined);
    }
    if (newRestId !== currentRestId) {
      setCurrentRestId(newRestId);
    }
    if (newMealType !== currentMealType) {
      setCurrentMealType(newMealType);
    }
  };

  /** invoked when user tries to change meal type or restaurant */
  const attemptUpdateMenuAttributes = (
    newRestId: number,
    newMealType: MealType,
  ) => {
    if (modified) {
      setMenuParamChange({
        mealType: newMealType,
        restaurantId: newRestId,
      });
    } else {
      updateMenuAttributes(newRestId, newMealType);
    }
  };

  const dateToDisplay = `${getWeekdayName(
    relevantMenu.date,
  )}, ${relevantMenu.date.getDate()} ${getMonthDisplayName([
    relevantMenu.date,
  ])}`;

  /** dishes available to add to the current menu (not included in the menu yet) */
  const availableDishes = allDishes.filter(
    (dish) => !relevantMenu.dishes.some((d) => d.id === dish.id),
  );

  const isMenuEmpty = relevantMenu.dishes.length === 0;

  return (
    <>
      {menuParamChange && (
        <ConfirmModal
          message="You have unsaved changes. They will be lost. Continue?"
          onConfirm={() => {
            updateMenuAttributes(
              menuParamChange.restaurantId,
              menuParamChange.mealType,
            );
            setMenuParamChange(undefined);
          }}
          onCancel={() => setMenuParamChange(undefined)}
        />
      )}
      <div className="menu-editor-top">
        <div className="item-selector-container">
          {restaurants.map((rest) => {
            const selectedClass =
              rest.id === relevantMenu.restaurantId ? ' selected-item' : '';
            return (
              <button
                key={rest.id}
                type="button"
                onClick={() =>
                  attemptUpdateMenuAttributes(rest.id, relevantMenu?.mealType)
                }
                className={`item-selector${selectedClass}`}
              >
                {rest.name}
              </button>
            );
          })}
        </div>
        <div className="date-display-text">{dateToDisplay}</div>
        <div className="item-selector-container">
          {mealTypes.map((meal) => {
            const selectedClass =
              meal === relevantMenu.mealType ? ' selected-item' : '';
            return (
              <button
                key={meal}
                type="button"
                onClick={() =>
                  attemptUpdateMenuAttributes(relevantMenu?.restaurantId, meal)
                }
                className={`item-selector${selectedClass}`}
              >
                {meal}
              </button>
            );
          })}
        </div>
        <AddDishes
          dishes={availableDishes}
          onAddDish={(dish) => {
            setModified(true);
            if (currentMenu) {
              setCurrentMenu({
                ...currentMenu,
                dishes: [...currentMenu.dishes, dish],
              });
            } else {
              setNewMenu({
                ...newMenu,
                dishes: [...newMenu.dishes, dish],
              });
            }
          }}
        />

        <DishesForMenuEditor
          onRemoveFromMenu={(dish) => {
            setModified(true);
            if (currentMenu) {
              setCurrentMenu({
                ...currentMenu,
                dishes: currentMenu.dishes.filter((d) => d.id !== dish.id),
              });
            } else {
              setNewMenu({
                ...newMenu,
                dishes: newMenu.dishes.filter((d) => d.id !== dish.id),
              });
            }
          }}
          dishes={relevantMenu.dishes}
          title="Menu:"
        />
        {isMenuEmpty && (
          <div className="note-text">
            No dishes in the menu yet. Add dishes to create a menu!
          </div>
        )}
        <button
          type="button"
          onClick={onSave}
          className="save-menu-button"
          disabled={!modified || isMenuEmpty}
        >
          Save Menu
        </button>
      </div>
    </>
  );
}
