import React from 'react';
import Modal from 'react-modal';
import { MealType, Order, Restaurant } from '../../common/types';
import './TodayMenuDetailsModal.scss';
import CloseIconSvg from '../../assets/close_icon.svg';

interface TodayMenuDetailsModalProps {
  restaurant: Restaurant;
  mealType: MealType;
  orders?: Order[];
  mealColor: string;
  modalIsOpen: boolean;
  closeModal: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * View for restaurant
 */
export default function TodayMenuDetailsModal(props: TodayMenuDetailsModalProps) {
  const { restaurant, mealType, orders, mealColor, modalIsOpen, closeModal } = props;
  // TODO change to real logic when the backend supports it
  const vegeterianOrders = orders.filter((order: Order) =>
    order.id === -1,
  );

  // TODO change to real logic when the backend supports it
  const veganOrders = orders.filter((order: Order) =>
    order.id === -1,
  );

  // // TODO change to real logic when the backend supports it
  // const menOrders = orders.filter((order: Order) =>
  //   order.id === -1,
  // );
  //
  // // TODO change to real logic when the backend supports it
  // const womenOrders = orders.filter((order: Order) =>
  //   order.id === -1,
  // );

  function afterOpenModal() {
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className='today-menu-modal'
      ariaHideApp={false}
    >
      <button type='button' onClick={closeModal}>
        <CloseIconSvg className='close-icon' height='22px' />
      </button>
      <div className='short-details'>
        <div>
          <div className='dot' style={{ backgroundColor: mealColor }} />
          <div className='meal-title'>{mealType}</div>
          <div className='title'>{restaurant.name}</div>
        </div>
        <div className='total-orders'>{orders.length}</div>
        <div className='special-orders'>
          <div className='vegetarian'>
            <div>{vegeterianOrders.length}</div>
            <div>Vegetarian</div>
          </div>
          <div className='vegan'>
            <div>{veganOrders.length}</div>
            <div>Vegan</div>
          </div>
        </div>
      </div>
      {/*  TODO: Export to separate component */}
      <table className='slot-table'>
        <tbody>
        <tr className='table-headers'>
          <th>Slot</th>
          <th>Men</th>
          <th>Women</th>
          <th>Vegetarians</th>
          <th>Vegans</th>
        </tr>
        <tr>
          {/* TODO: Fill with real data */}
        </tr>
        </tbody>
      </table>
      <div className='menu-details'>
        <div className='menu-title'>
          <div className='text'>Menu</div>
        </div>
        {/*  TODO Add meal data */}
      </div>
    </Modal>
  );
}
