import React, { useContext, useState } from 'react';
import { DataServiceContext } from '../../common/contexts';
import { Dish } from '../../common/types';
import CreateDishModal from '../EditDishModal/EditDishModal';
import DishView from '../DishView/DishView';

import './DishesList.scss';
import {notifySuccess} from "../../common/notifyToast";

type DishesProps = {
  dishes: Dish[];
  onUpdate: () => void;
};

/**
 * View for edit menus page
 */
export default function Dishes({ dishes, onUpdate }: DishesProps) {
  const [showCreateDish, setShowCreateDish] = useState(false);
  const [editedDish, setEditedDish] = useState<Dish>();
  const dataService = useContext(DataServiceContext);

  const onDeleteDish = async (id: number) => {
    await dataService.deleteDish(id);

    const dishName = dishes.find((d) => d.id === id).name
    if (dishName) {
      notifySuccess(`dish ${dishName.en} deleted`);
    }
    onUpdate();
  };
  return (
    <>
      {showCreateDish && (
        <CreateDishModal
          onUpdate={onUpdate}
          onClose={() => {
            setShowCreateDish(false);
            setEditedDish(undefined);
          }}
          startDish={editedDish}
        />
      )}
      <div className="dishes-top-container">
        <button
          type="button"
          onClick={() => setShowCreateDish(true)}
          className="add-dish-button"
        >
          Add dish
        </button>
        <div className="dishes-list-container">
          {dishes.map((dish) => (
            <div key={dish.id} className="dish-list-row">
              <DishView dish={dish} />
              <div className="dish-side-buttons-container">
                <button
                  onClick={() => {
                    setEditedDish(dish);
                    setShowCreateDish(true);
                  }}
                  type="button"
                  className="dish-side-button"
                >
                  Edit
                </button>
                <button
                  onClick={() => onDeleteDish(dish.id)}
                  type="button"
                  className="dish-side-button"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
