import { MealType, Restaurant } from './types';

const GRACE_PERIOD_TO_TAKE_ORDERS = 5; // number of minutes after the start time has started.

/** Return the last start time for eating round, in a restaurant for specific meal */
export function getLastStartTimeRoundPerMeal(rest: Restaurant, mealType: MealType) {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  const minStartTime = today;

  const lastStartRoundTime = rest.eatingRounds.reduce((lastStartRound, eatingRound) => {
    if (eatingRound.mealType !== mealType) {
      return lastStartRound;
    }
    if (eatingRound.numOfSeatsLeft === 0) {
      return lastStartRound;
    }
    return eatingRound.startTime > lastStartRound ? eatingRound.startTime : lastStartRound;
  }, minStartTime);

  return lastStartRoundTime;
}

/** Return if time is after the grace period that can be taking orders */
export function isTimePassedGracePeriod(time: Date) {
  const now = new Date();
  now.setMinutes(now.getMinutes() + GRACE_PERIOD_TO_TAKE_ORDERS);
  return time < now;
}
