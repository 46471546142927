import React from 'react';
import { MealType, Order, Restaurant } from '../../common/types';
import TodayMealCardView from '../../components/TodayMealCardView/TodayMealCardView';

interface TodayMealViewProps {
  restaurant: Restaurant;
  mealType: MealType;
  todayOrders: Order[];
}

export function TodayMealView(props: TodayMealViewProps) {
  const { restaurant, mealType, todayOrders } = props;
  const { todayMenus, eatingRounds } = restaurant;
  const ordersByMealType = todayOrders.filter((order) =>
    eatingRounds.find(
      (eatingRound) =>
        eatingRound.id === order.eatingRoundId &&
        eatingRound.mealType === mealType,
    ),
  );

  return (
    todayMenus[mealType] && (
      <TodayMealCardView
        key={restaurant.name}
        restaurant={restaurant}
        mealType={mealType}
        orders={ordersByMealType}
      />
    )
  );
}

const getTodayViewByMeal = (
  restaurants: Restaurant[],
  mealType: MealType,
  todayOrders: Order[],
) => {
  const isMenuForMealToday = restaurants.find(
    (rest) => rest.todayMenus[mealType],
  );

  if (!isMenuForMealToday) {
    return <div/>;
  }

  return restaurants.map((rest) => (
    <TodayMealView
      key={rest.id}
      restaurant={rest}
      mealType={mealType}
      todayOrders={todayOrders}
    />
  ));
};

interface TodayViewProps {
  todayOrders: Order[];
  restaurants: Restaurant[];
}

/**
 * View for admin home page
 */
export default function AdminTodayView(props: TodayViewProps) {
  const { todayOrders, restaurants } = props;
  return (
    <div className='restaurants'>
      <div className='restaurants-meals'>
        {getTodayViewByMeal(restaurants, 'Breakfast', todayOrders)}
        {getTodayViewByMeal(restaurants, 'Lunch', todayOrders)}
        {getTodayViewByMeal(restaurants, 'Dinner', todayOrders)}
      </div>
    </div>
  );
}
