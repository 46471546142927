/**
 * Returns dates for the coming week days (in current working week only) with time
 * set to 00:00 AM
 */
export function getComingWeekDates() {
  const days = [0, 1, 2, 3, 4, 5, 6]
    .map((offset) => {
      const day = new Date();
      day.setDate(day.getDate() + offset);
      day.setHours(0);
      day.setMinutes(0);
      day.setSeconds(0);
      return day;
    })
    .filter((date) => date.getDay() !== 5 && date.getDay() !== 6);
  // .filter((date) => date.getDay() >= new Date().getDay());
  return days;
}

/**
 * Get display of month name for given dates.
 * Will show 'August/September' for dates spanning from august to september, and 'March' for
 * dates only on March.
 */
export function getMonthDisplayName(dates: Date[]) {
  const startMonth = dates[0].toLocaleString('default', { month: 'long' });
  const endMonth = dates[dates.length - 1].toLocaleString('default', { month: 'long' });
  if (startMonth !== endMonth) {
    return `${startMonth}/${endMonth}`;
  }
  return startMonth;
}

/** Returns weekday name for the given date */
export function getWeekdayName(date: Date) {
  return date.toLocaleDateString('default', { weekday: 'short' });
}

/** Convert date to time in format hh:mm */
export function getTimeDisplay(time: Date) {
  return time.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' });
}

export function getDateString(date: Date) {
  return date.toLocaleDateString('he');
}
