import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DataServiceContext } from '../../common/contexts';
import {
  Language,
  MealType,
  Menu,
  Order,
  Restaurant,
} from '../../common/types';
import EmployeeMenuView from '../EmployeeMenuView/EmployeeMenuView';
import 'react-toastify/dist/ReactToastify.css';
import './RestaurantPageView.scss';
import RoundView from '../RoundView/RoundView';
import {
  getLastStartTimeRoundPerMeal,
  isTimePassedGracePeriod,
} from '../../common/timeUtils';
import BackButtonSvg from '../../assets/back_button.svg';

type RestaurantPageParams = {
  id: string;
};

interface RestaurantPageProps {
  onEatingRoundBooked: (eatingRoundId: number, mealType: MealType) => void;
  onUpdateBook: (orderId: number, eatingRoundId: number) => void;
  restaurants: Restaurant[];
  todayOrders: Order[];
}

/**
 * View for restaurant
 */
export default function RestaurantPageView(props: RestaurantPageProps) {
  const { onEatingRoundBooked, onUpdateBook, restaurants, todayOrders } = props;
  const [lang, setLang] = useState<Language>('en');
  const [menusPerMeal, setMenuForMeal] = useState<Menu[]>();
  const [eatingRoundId, setEatingRoundId] = useState<number>();

  const { id } = useParams<RestaurantPageParams>();
  const restaurantId = parseInt(id, 10);
  const dataService = useContext(DataServiceContext);
  const urlParams = new URLSearchParams(window.location.search);

  const mealType = urlParams.get('meal') as MealType;

  const restaurant = restaurants.find((rest) => rest.id === restaurantId);
  const history = useHistory();

  useEffect(() => {
    dataService.getMenus(restaurantId).then((menusRes: Menu[]) => {
      // filter menus according to meal
      let menus = menusRes.filter((m) => m.mealType === mealType);
      // filter past menus
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      menus = menus.filter((m) => m.date >= today);
      setMenuForMeal(menus);
    });
  }, []);

  const bookedEatingRound = todayOrders.find((todayOrder) =>
    restaurant.eatingRounds.some(
      (eatingRound) => eatingRound.id === todayOrder.eatingRoundId,
    ),
  );

  useEffect(() => {
    if (bookedEatingRound) {
      setEatingRoundId(bookedEatingRound.eatingRoundId);
    }
  }, [bookedEatingRound]);

  if (!menusPerMeal) {
    return <div>Loading...</div>;
  }

  const lastStartRoundTime = getLastStartTimeRoundPerMeal(restaurant, mealType);
  const isTimePassed = isTimePassedGracePeriod(lastStartRoundTime);
  const isSeatsLeft = restaurant.eatingRounds.find(
    (eatingRound) => eatingRound.numOfSeatsLeft > 0,
  );
  const canBook = !isTimePassed && isSeatsLeft;

  return (
    <div className="restaurant-page-container">
      <div className="back-button" onClick={() => history.goBack()}>
        <BackButtonSvg className="menu-item-icon" height="15px" />
      </div>
      <img className="restaurant-img" />
      <div className="restaurant-page">
        <div className="name">{restaurant.name}</div>
        <div className="description">{restaurant.description}</div>
        <div className="rounds-container">
          {restaurant.eatingRounds.map(
            (er) =>
              er.mealType === mealType && (
                <RoundView
                  key={er.id}
                  eatingRound={er}
                  selectedRoundId={eatingRoundId}
                  onRoundSelected={() => setEatingRoundId(er.id)}
                />
              ),
          )}
        </div>
        {canBook ? (
          <button
            className="book-button main-button"
            type="button"
            onClick={() => {
              if (bookedEatingRound) {
                onUpdateBook(bookedEatingRound.id, eatingRoundId);
              } else {
                onEatingRoundBooked(eatingRoundId, mealType);
              }
              history.goBack();
            }}
            disabled={!eatingRoundId}
          >
            {bookedEatingRound ? 'Edit Booking' : 'Book for Today'}
          </button>
        ) : (
          <div className="rounds-message">We are no longer taking bookings</div>
        )}
        <div className="separator" />

        <div className="menus-container">
          <div className="lang-button-container">
            <button
              className={`lang-button ${lang === 'en' && 'selected'}`}
              type="button"
              onClick={() => setLang('en')}
            >
              English
            </button>
            <button
              className={`lang-button ${lang === 'he' && 'selected'}`}
              type="button"
              onClick={() => setLang('he')}
            >
              עברית
            </button>
          </div>
          <div className="menus-title">Menus</div>
          {menusPerMeal.map((menuPerMeal) => (
            <EmployeeMenuView
              key={menuPerMeal.id}
              menu={menuPerMeal}
              lang={lang}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
