import React, { useEffect, useRef, useState } from 'react';
import './AppContent.scss';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MealType, Restaurant, User } from '../../common/types';

import { DataServiceContext } from '../../common/contexts';
import RestaurantPageView from '../RestaurantPageView/RestaurantPageView';
import DataService from '../../services/DataService';
import config from '../../config';
import Restaurants from '../Restaurants/Restaurants';
import SelectRoundModal from '../SelectRoundModal/SelectRoundModal';
import AdminTodayView from '../../pages/AdminTodayView/AdminTodayView';
import EditMenus from '../../pages/EditMenus/EditMenus';
import { useOrders } from './useOrders';
import HamburgerMenuSvg from '../../assets/hamburger_menu.svg';
import RestaurantHome from '../../pages/RestaurantHome/RestaurantHome';
import AddCard from "../../pages/AddCard/AddNewCard";
import AddManualPurchase from "../../pages/AddManualPurchase/addManualPurchase";
import AddUserMadePurchase from "../../pages/UserMadePurchase/addUserMadePurchase";

type LocationState = {
  displayRoundModal:
    | {
    restaurantId: number;
    mealType: MealType;
    orderId: number;
  }
    | undefined;
};

interface AppContentProps {
  onLogout: () => Promise<void>;
  user: User;
}

export default function AppContent(props: AppContentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onLogout, user } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMenuOpen, setIsMenuOpen] = useState(false); // used in mobile view
  const [isLoading, setIsLoading] = useState(true);

  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const dataServiceRef = useRef<DataService>(new DataService(config.API_PATH));

  const getRestaurants = async () => {
    dataServiceRef.current.getRestaurants().then((restaurantsDataRes) => {
      setRestaurants(restaurantsDataRes);
      setIsLoading(false);
    });
  };

  const {
    bookEatingRound,
    deleteBookEatingRound,
    updateBookEatingRound,
    todayOrders,
  } = useOrders(dataServiceRef.current, user, getRestaurants);

  useEffect(() => {
    if (user) {
      getRestaurants();
    }
  }, [user]);

  const withHeader = (component: JSX.Element) => (
    <div className='app-page'>
      <div className='header'>
        <div className='header-menu-icon' onClick={() => setIsMenuOpen(true)}>
          <HamburgerMenuSvg height='28px' />
        </div>
        <div className='header-menu-title'>PANDA</div>
      </div>
      <div className='page'>{component}</div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const restaurantPageView = () => (
    <RestaurantPageView
      restaurants={restaurants}
      onEatingRoundBooked={bookEatingRound}
      onUpdateBook={updateBookEatingRound}
      todayOrders={todayOrders}
    />
  );

  return (
    <DataServiceContext.Provider value={dataServiceRef.current}>
      {user.isTablet ? (<RestaurantHome />) :
        <div className='app-content'>
          {user.isRestaurantWorker ? (
            <Switch>
              <Route
                exact
                path={['/', '/home']}
                render={() => withHeader(
                  <AdminTodayView
                    restaurants={restaurants}
                    todayOrders={todayOrders}
                  />)}
              />
              <Route
                path='/menus'
                render={() => withHeader(
                    <EditMenus restaurants={restaurants} setRestaurants={setRestaurants}/>
                )}
              />
              <Route
                exact
                path='/restaurants'
                render={() =>
                  withHeader(
                    <Restaurants
                      restaurants={restaurants}
                      todayOrders={todayOrders}
                    />,
                  )
                }
              />
              <Route path='/restaurant/:id' render={restaurantPageView} />
            </Switch>
          ) :(
            <Switch>
              <Route
                exact
                path={['/', '/home','/purchase/user_created/']}
                render={() =>
                  withHeader(
                    <AddUserMadePurchase
                      restaurants={restaurants}
                    />,
                  )
                }
              />
              {user.isFrontDeskWorker? (
                      <switch>
                        <Route path='/register/card' render={() => withHeader(<AddCard/>)}/>
                        <Route path='/manual_purchase'
                            render={
                              () => withHeader(<AddManualPurchase restaurants={restaurants}/>)
                            }/>
                      </switch>):
                  (<switch><div>404</div></switch>)}
              <Route>
                <div>404</div>
              </Route>
            </Switch>
          )}

          <ToastContainer />
          <Route
            path='/'
            render={(routeProps) => {
              const locationState = routeProps.location.state as LocationState;
              if (locationState && locationState.displayRoundModal) {
                const { restaurantId, mealType, orderId } =
                  locationState.displayRoundModal;
                return (
                  <SelectRoundModal
                    restaurantId={restaurantId}
                    restaurants={restaurants}
                    mealType={mealType}
                    order={todayOrders.find((order) => order.id === orderId)}
                    onBookRound={bookEatingRound}
                    onUpdateBooking={updateBookEatingRound}
                    onDeleteOrder={deleteBookEatingRound}
                  />
                );
              }
              return null;
            }}
          />
        </div>
      }
    </DataServiceContext.Provider>
  );
}
