import React from 'react';
import { getTimeDisplay } from '../../common/dateUtils';
import { isTimePassedGracePeriod } from '../../common/timeUtils';
import { EatingRound } from '../../common/types';
import './RoundView.scss';

interface RoundViewProps {
  eatingRound: EatingRound;
  selectedRoundId?: number;
  onRoundSelected: () => void;
}

export default function RoundView(props: RoundViewProps) {
  const { eatingRound, selectedRoundId, onRoundSelected } = props;
  const { id, startTime, endTime, numOfSeatsLeft } = eatingRound;
  const isTimePassed = isTimePassedGracePeriod(startTime);

  const canBook = numOfSeatsLeft > 0 && !isTimePassed;

  return (
    <div
      onClick={() => {
        if (canBook) {
          onRoundSelected();
        }
      }}
      className={`eating-round ${!canBook ? 'disabled' : ''} 
      ${selectedRoundId === id ? 'selected' : ''}`}
    >
      <div className="times">{`${getTimeDisplay(startTime)} - ${getTimeDisplay(
        endTime,
      )}`}</div>
      <div className="seats-left">{`${numOfSeatsLeft} Spots Left`}</div>
    </div>
  );
}
