import axios, {AxiosInstance} from 'axios';
import Cookies from 'js-cookie';
import {
  Card,
  Dish,
  DishType,
  ManualPurchaseBase,
  MealType,
  Menu,
  Order,
  OrderBase,
  PurchaseBase, PurchaseResponse,
  PurchaseStatus,
  Restaurant, UserMadePurchase,
} from '../common/types';
import config from '../config';

const MealTypeMapping: Record<string, MealType> = {
  BKF: 'Breakfast',
  LNC: 'Lunch',
  DNR: 'Dinner',
};

const DishTypeMapping: Record<string, DishType> = {
  VGN: 'Vegan',
  VGT: 'Vegetarian',
};

function reverseMapping<T extends string>(mapping: Record<string, T>, value: T) {
  const keys = Object.keys(mapping);
  const match = keys.find((k) => mapping[k] === value);
  return match ?? '';
}

const convertStringTimeToDate = (time: string) => {
  const timeArr = time.split(':');
  const todayTime = new Date();
  todayTime.setHours(parseInt(timeArr[0], 10));
  todayTime.setMinutes(parseInt(timeArr[1], 10));
  return todayTime;
};

const convertToStringDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export default class DataService {
  axiosClient: AxiosInstance;

  constructor(apiBaseUrl: string) {
    const csrftoken = Cookies.get(config.CSRF_TOKEN_COOKIE_NAME);
    this.axiosClient = axios.create({
      baseURL: apiBaseUrl,
      withCredentials: true,
      headers: {
        [config.CSRF_TOKEN_HEADER_NAME]: csrftoken,
      },
    });
  }

  /** ---RESTAURANTS--- */

  /** Get list of all restaurants */
  async getRestaurants(): Promise<Restaurant[]> {
    const response = await this.axiosClient.get('api/restaurants/');
    const restaurants = response.data.data;
    return restaurants.map((restaurant) => ({
      ...restaurant,
      eatingRounds: restaurant.eating_rounds.map((eatingRound) => ({
        ...eatingRound,
        startTime: convertStringTimeToDate(eatingRound.start_time),
        endTime: convertStringTimeToDate(eatingRound.end_time),
        numOfSeatsLeft: eatingRound.num_of_seats_left,
        mealType: MealTypeMapping[eatingRound.meal_type],
      })),
      todayMenus: {
        ...Object.keys(restaurant.today_menus).reduce((newObj, key) => {
          newObj[MealTypeMapping[key]] = restaurant.today_menus[key];
          return newObj;
        }, {}),
      },
    })) as Restaurant[];
  }


  /** Get next eating round for a specific restaurant */
  async getNextEatingRound(restaurantID:number):Promise<string>{
    const response = await this.axiosClient.get(`api/restaurants/${restaurantID}/`, {
      withCredentials: true,
    });
    return response.data.data.next_round
  }

  /** ---ORDERS--- */

  /** Get list of all restaurants
   * @param startDate - filter by start date, include the start date, format: ['2021-08-15']
   * @param endDate - filter by end date, Not include the end date, format: ['2021-08-15']
   */
  async getOrders(startDate?: Date, endDate?: Date): Promise<Order[]> {
    const apiPath = 'api/orders';
    const response = await this.axiosClient.get(apiPath, {
      params: {
        start_date: startDate && convertToStringDate(startDate),
        end_date: endDate && convertToStringDate(endDate),
      },
    });

    const orders = response.data.data;
    return orders.map((order) => ({
      ...order,
      date: new Date(order.date),
      eatingRoundId: order.eating_round_id,
      mealType: MealTypeMapping[order.meal_type],
      restaurantId: order.restaurant_id,
    })) as Order[];
  }

  /** Add new order */
  async addOrder(newOrder: OrderBase): Promise<Order> {
    const orderData = {
      eating_round_id: newOrder.eatingRoundId,
      date: convertToStringDate(newOrder.date),
    };
    const response = await this.axiosClient.post('api/orders/', orderData);
    const rawOrder = response.data.data;
    return {
      ...rawOrder,
      date: new Date(rawOrder.date),
      eatingRoundId: rawOrder.eating_round_id,
      mealType: MealTypeMapping[rawOrder.meal_type],
      restaurantId: rawOrder.restaurant_id,
    };
  }

  /** Delete an order */
  async deleteOrder(orderId: number): Promise<void> {
    const response = await this.axiosClient.delete(`api/orders/${orderId}`);
    return response.data.data;
  }

  /** Update an order */
  async updateOrder(orderId: number, newOrder: OrderBase): Promise<Order> {
    const orderData = {
      eating_round_id: newOrder.eatingRoundId,
      date: convertToStringDate(newOrder.date),
    };

    const response = await this.axiosClient.patch(`api/orders/${orderId}/`, orderData);
    const rawOrder = response.data.data;
    return {
      ...rawOrder,
      date: new Date(rawOrder.date),
      eatingRoundId: rawOrder.eating_round_id,
      mealType: MealTypeMapping[rawOrder.meal_type],
      restaurantId: rawOrder.restaurant_id,
    };
  }

  /** ---DISHES--- */

  /** Delete an order */
  async getDishes(): Promise<Dish[]> {
    const apiPath = 'api/dishes';
    const response = await this.axiosClient.get(apiPath);
    const dishes = response.data.data;
    return dishes.map((dish) => ({
      id: dish.id,
      name: dish.name,
      description: dish.description,
      type: DishTypeMapping[dish.type],
      calories: dish.calories,
    })) as Dish[];
  }

  /** ---MENUS--- */

  /** Get list of all menus
   * @param restaurantId - filter by restaurant id
   * @param startDate - filter by start date, include the start date, format: ['2021-08-15']
   * @param endDate - filter by end date, Not include the end date, format: ['2021-08-15']
   */
  async getMenus(restaurantId?: number, startDate?: Date, endDate?: Date): Promise<Menu[]> {
    const apiPath = 'api/menus';
    const response = await this.axiosClient.get(apiPath, {
      params: {
        restaurant: restaurantId,
        start_date: startDate && convertToStringDate(startDate),
        end_date: endDate && convertToStringDate(endDate),
      },
    });

    const menus = response.data.data;
    return menus.map((menu) => ({
      ...menu,
      date: new Date(menu.date),
      dishes: menu.dishes.map((dish) => ({ ...dish, type: DishTypeMapping[dish.type] })),
      mealType: MealTypeMapping[menu.meal_type],
      restaurantId: parseInt(menu.restaurant_id, 10),
    })) as Menu[];
  }

  /**
   * creates a new menu
   */
  async createMenu(
    dishes: Dish[],
    restaurantId: number,
    date: Date,
    mealType: MealType,
  ): Promise<Menu> {
    const apiPath = 'api/menus/';
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const response = await this.axiosClient.post(
      apiPath,
      {
        dishes: dishes.map((d) => d.id),
        restaurant_id: restaurantId,
        date: formattedDate,
        meal_type: reverseMapping(MealTypeMapping, mealType),
      }
    );

    return response.data.data as Menu;
  }

  /**
   * update existing menu's dishes
   */
  async updateMenu(menuId: number, dishes: Dish[]): Promise<Menu> {
    const apiPath = `api/menus/${menuId}/`;
    const response = await this.axiosClient.patch(
      apiPath,
      {
        dishes: dishes.map((d) => d.id),
      }
    );

    return response.data.data as Menu;
  }

  /**
   * creates a new dish
   */
  async createDish(dish: Omit<Dish, 'id'>): Promise<Dish> {
    const apiPath = 'api/dishes/';
    const response = await this.axiosClient.post(
      apiPath,
      {
        name: dish.name,
        description: dish.description,
        calories: dish.calories,
        type: dish.type ? reverseMapping(DishTypeMapping, dish.type) : undefined,
      }
    );

    return response.data.data as Dish;
  }

  /**
   * updates an existing dish
   */
  async updateDish(dish: Dish): Promise<Dish> {
    const apiPath = `api/dishes/${dish.id}/`;
    const response = await this.axiosClient.put(
      apiPath,
      {
        name: dish.name,
        description: dish.description,
        calories: dish.calories,
        type: dish.type ? reverseMapping(DishTypeMapping, dish.type) : undefined,
      }
    );

    return response.data.data as Dish;
  }

  /**
   * deletes a dish from the dish pool
   */
  async deleteDish(id: number): Promise<Dish> {
    const apiPath = `api/dishes/${id}`;
    const response = await this.axiosClient.delete(apiPath);

    return response.data.data as Dish;
  }

  /** ---PURCHASES--- */

  /**
   * Create new purchase, return whether the purchase was created successfully.
   * */
    async addPurchase(newPurchase: PurchaseBase): Promise<PurchaseResponse> {
    const purchaseData = {
      restaurant: newPurchase.restaurantId,
      card_id: newPurchase.cardId,
      number_of_guests: newPurchase.numberOfGuests,
    };
    try {
      const response = await this.axiosClient.post('api/purchases/', purchaseData);
      return {
        status: PurchaseStatus.successful,
        userEmail: response.data.data.purchases[0].user_email
      }
    }
    catch (e) {
      if (e.response.status === 409) {
        return {status: PurchaseStatus.alreadyExists};
      }else if (e.response.status === 422){
        return {status: PurchaseStatus.noEatingRound};
      }else if (e.response.status === 400 && e.response.data.message &&
                e.response.data.message.toLowerCase().includes("cannot find card")) {
        return {status: PurchaseStatus.cardDoesNotExist}
      }
      return {status: PurchaseStatus.failed};
    }
  }

  /**
   * Create new purchase by manual input, return whether the purchase was created successfully.
   * */
  async addManualPurchase(newPurchase: ManualPurchaseBase): Promise<PurchaseStatus> {
    const purchaseData = {
      restaurant: newPurchase.restaurantId,
      number_of_guests: newPurchase.numberOfGuests,
      user_email: newPurchase.userEmail,
    };
    try {
      await this.axiosClient.post('api/purchase/manual/', purchaseData);
      return PurchaseStatus.successful
    } catch (e) {
      if (e.response.status === 404) {
        return PurchaseStatus.userDoesNotExists;
      }
      if (e.response.status === 409) {
        return PurchaseStatus.alreadyExists;
      }else if (e.response.status === 422){
        return PurchaseStatus.noEatingRound;
      }
      return PurchaseStatus.failed;
    }
  }

  /**  Create new purchase by user and return purchase status ----*/
  async addUserMadePurchase(newPurchase:UserMadePurchase): Promise<PurchaseStatus>{
    const purchaseData = {
      restaurant: newPurchase.restaurantId,
      number_of_guests: newPurchase.numberOfGuests,
    };
    try{
      await this.axiosClient.post('api/purchase/user_created/', purchaseData);
      return PurchaseStatus.successful
    } catch (e) {
      if (e.response.status === 404) {
        return PurchaseStatus.userDoesNotExists;
      }
      if (e.response.status === 409) {
        return PurchaseStatus.alreadyExists;
      }else if (e.response.status === 422){
        return PurchaseStatus.noEatingRound;
      }
      return PurchaseStatus.failed;
    }

  }

  /** --- REGISTER ----*/

  async registerCard(newCard: Card): Promise<Boolean>{
    const cardData = {
      card_id: newCard.cardId,
      email: newCard.email,
      user_name: newCard.username
    };
    try {
      await this.axiosClient.post('api/card_register/', cardData);
      return true;
    } catch (e){
      return false;
    }
  }
}

