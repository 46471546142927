import React, { useContext, useState } from 'react';
import { DataServiceContext } from '../../common/contexts';
import { Dish, DishType, LocalizedString } from '../../common/types';
import Modal from '../Modal/Modal';

import './EditDishModal.scss';
import {notifySuccess} from "../../common/notifyToast";

const MAX_DISH_DESC_LENGTH = 150;
const MAX_DISH_NAME_LENGTH = 40;

type CreateDishModalProps = {
  onClose: () => void;
  onUpdate: () => void;
  startDish?: Dish;
};

export default function CreateDishModal(props: CreateDishModalProps) {
  const { onClose, onUpdate, startDish } = props;
  const [desc, setDesc] = useState<LocalizedString>(
    startDish?.description ?? { he: '', en: '' },
  );
  const [name, setName] = useState<LocalizedString>(
    startDish?.name ?? { he: '', en: '' },
  );
  const dataService = useContext(DataServiceContext);
  const [loading, setLoading] = useState(false);
  const [calories, setCalories] = useState<number | undefined>(
    startDish?.calories ?? undefined,
  );
  const [dishType, setDishType] = useState<DishType | undefined>(
    startDish?.type ?? undefined,
  );

  const isVegan = dishType === 'Vegan';
  const isVegetarian = dishType === 'Vegetarian';
  const isRegular = dishType === undefined;

  const onCreateDish = async () => {
    setLoading(true);
    if (startDish) {
      await dataService.updateDish({
        id: startDish.id,
        name,
        description: desc,
        calories,
        type: dishType,
      });
    } else {
      await dataService.createDish({
        name,
        description: desc,
        calories,
        type: dishType,
      });
    }

    notifySuccess(`dish ${name.en} saved`);
    onUpdate();
    setLoading(false);
    onClose();
  };

  const getIsCreateEnabled = () =>
    !loading &&
    name.en?.length > 1 &&
    name.he?.length > 1 &&
    desc.he?.length > 1 &&
    desc.en?.length > 1;

  return (
    <Modal onClose={onClose}>
      <>
        <div className="edit-dish-title">
          {startDish ? 'Edit' : 'Create new'} dish
        </div>
        <div className="separator" />
        <div className="edit-dish-top">
          <label className="input-label ltr-text" htmlFor="en_name">
            Dish name (English)
          </label>
          <input
            onInput={(event) => {
              setName({
                ...name,
                en: (event.target as HTMLInputElement).value,
              });
            }}
            name="en_name"
            type="text"
            defaultValue={name.en}
            className="edit-dish"
            placeholder="Dish name (English)"
            maxLength={MAX_DISH_NAME_LENGTH}
          />
          <label className="input-label ltr-text" htmlFor="en_desc">
            Dish description (English)
          </label>
          <textarea
            onInput={(event) => {
              setDesc({
                ...desc,
                en: (event.target as HTMLInputElement).value,
              });
            }}
            name="en_desc"
            className="edit-dish edit-dish-ta"
            defaultValue={desc.en}
            placeholder="Dish description (English)"
            maxLength={MAX_DISH_DESC_LENGTH}
          />
          <label className="input-label rtl-text" htmlFor="he_name">
            שם המנה (עברית)
          </label>
          <input
            onInput={(event) => {
              setName({
                ...name,
                he: (event.target as HTMLInputElement).value,
              });
            }}
            name="he_name"
            dir="rtl"
            type="text"
            defaultValue={name.he}
            className="edit-dish"
            placeholder="שם המנה (עברית)"
            maxLength={MAX_DISH_NAME_LENGTH}
          />
          <label className="input-label rtl-text" htmlFor="he_desc">
            תיאור המנה (עברית)
          </label>
          <textarea
            onInput={(event) => {
              setDesc({
                ...desc,
                he: (event.target as HTMLInputElement).value,
              });
            }}
            dir="rtl"
            name="he_desc"
            className="edit-dish edit-dish-ta"
            defaultValue={desc.he}
            placeholder="תיאור המנה (עברית)"
            maxLength={MAX_DISH_DESC_LENGTH}
          />

          <div className="dish-type-options-container">
            <div
              className={`dish-type-option${
                isRegular ? ' dish-type-selected' : ''
              }`}
              onClick={() => setDishType(undefined)}
            >
              Regular dish
            </div>
            <div
              className={`dish-type-option${
                isVegan ? ' dish-type-selected' : ''
              }`}
              onClick={() => setDishType('Vegan')}
            >
              Vegan
            </div>
            <div
              className={`dish-type-option${
                isVegetarian ? ' dish-type-selected' : ''
              }`}
              onClick={() => setDishType('Vegetarian')}
            >
              Vegetarian
            </div>
          </div>
          <label className="input-label ltr-text" htmlFor="calories">
            Calories (optional)
          </label>
          <input
            onInput={(event) => {
              const calValue =
                Number.parseInt((event.target as HTMLInputElement).value, 10) ??
                undefined;
              setCalories(calValue);
            }}
            name="calories"
            type="number"
            dir="ltr"
            min="0"
            step="1"
            defaultValue={startDish?.calories ?? ''}
            className="edit-dish"
            placeholder="Calories"
            maxLength={MAX_DISH_NAME_LENGTH}
          />
        </div>
        <div className="separator" />
        <button
          type="button"
          onClick={onCreateDish}
          className="edit-dish-button"
          disabled={!getIsCreateEnabled()}
        >
          {startDish ? 'Save' : 'Create'} dish
        </button>
      </>
    </Modal>
  );
}
