import React from 'react';
import { MealType, Order, Restaurant } from '../../common/types';
import './TodayMealCardView.scss';
import TodayMenuDetailsModal from '../TodayMenuDetailsModal/TodayMenuDetailsModal';


const MEAL_COLORS = {
  'Breakfast': '#E9B971',
  'Lunch': '#57E9E9',
  'Dinner': '#EE83E3',
};

interface TodayMealCardViewProps {
  restaurant: Restaurant;
  mealType: MealType;
  orders?: Order[];
}

/**
 * View for restaurant
 */
export default function TodayMealCardView(props: TodayMealCardViewProps) {
  const { restaurant, mealType, orders } = props;
  const mealColor = MEAL_COLORS[mealType];
  // TODO change to real logic when the backend supports it
  const vegeterianOrders = orders.filter((order: Order) =>
    order.id === -1,
  );
  // TODO change to real logic when the backend supports it
  const veganOrders = orders.filter((order: Order) =>
    order.id === -1,
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='today-card-view'>
      <TodayMenuDetailsModal
        restaurant={restaurant}
        mealType={mealType}
        orders={orders}
        mealColor={mealColor}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen} />
      <div className='today-restaurant'>
        <div className='dot' style={{ backgroundColor: mealColor }} />
        <div className='meal-title'>{mealType}</div>
        <div className='title'>{restaurant.name}</div>
        <div className='total-orders'>{orders.length}</div>
        <div className='special-orders'>
          <div className='vegetarian'>
            <div>{vegeterianOrders.length}</div>
            <div>Vegetarian</div>
          </div>
          <div className='vegan'>
            <div>{veganOrders.length}</div>
            <div>Vegan</div>
          </div>
        </div>
      </div>
      <div className='see-more' style={{ backgroundColor: mealColor }}>
        <button
          type='button'
          className='see-more-button'
          onClick={openModal}
        >
          See More
        </button>
      </div>
    </div>
  );
}
