import { toast } from 'react-toastify';

export const notifyError = (msg?: string) =>
  toast.info(msg ?? `Something went wrong`, {
    className: 'toast-massage',
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export const notifySuccess = (msg: string) =>
  toast.info(msg, {
    className: 'toast-massage',
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
