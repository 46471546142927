import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  getMonthDisplayName,
  getComingWeekDates,
  getWeekdayName,
} from '../../common/dateUtils';
import { Menu, Restaurant, Dish, MealType } from '../../common/types';
import Dishes from '../../components/DishesList/DishesList';
import Modal from '../../components/Modal/Modal';
import MenuEditor from '../../components/MenuEditor/MenuEditor';

import './EditMenus.scss';
import { DataServiceContext } from '../../common/contexts';
import MenuItemView from '../../components/MenuItemView/MenuItemView';

const mealTypes: MealType[] = ['Breakfast', 'Lunch', 'Dinner'];
function filterMenusForDate(menus: Menu[], date: Date) {
  return menus.filter(
    (menu) =>
      menu.date.toLocaleDateString('he') === date.toLocaleDateString('he'),
  );
}

function areAllMenusIncluded(menus: Menu[], restaurants: Restaurant[]) {
  return !restaurants.some((rest) =>
    mealTypes.some(
      (mt) =>
        !menus.some(
          (menu) => menu.mealType === mt && menu.restaurantId === rest.id,
        ),
    ),
  );
}

type EditMenusProps = {
  restaurants: Restaurant[];
  setRestaurants: React.Dispatch<React.SetStateAction<Restaurant[]>>;
};

/**
 * View for edit menus page
 */
export default function EditMenus({ restaurants, setRestaurants}: EditMenusProps) {
  const todayDate = useRef(new Date());
  const dataService = useContext(DataServiceContext);
  const dates = useRef(getComingWeekDates());
  const [dishes, setDishes] = useState<Dish[]>([]);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [newMenuDate, setNewMenuDate] = useState<Date | undefined>();
  const [editedMenu, setEditedMenu] = useState<Menu | undefined>();

  const getDishes = async () => {
    dataService.getDishes().then((dishesResult) => setDishes(dishesResult));
  };

  const updateMenus = async () => {
    const startDate = dates.current[0];
    const endDate = dates.current[dates.current.length - 1];
    const menusForDates = await dataService.getMenus(
      undefined,
      startDate,
      endDate,
    );
    setMenus(menusForDates);
  };

  const onAddMenu = (date: Date) => {
    setNewMenuDate(date);
  };

  useEffect(() => {
    updateMenus();
    getDishes();
  }, []);

  const menuEditorModal = (
    <Modal
      onClose={() => {
        setEditedMenu(undefined);
        setNewMenuDate(undefined);
      }}
    >
      <MenuEditor
        initialMenu={editedMenu}
        dateForNewMenu={newMenuDate}
        restaurants={restaurants}
        setRestaurants={setRestaurants}
        allDishes={dishes}
        allMenus={menus}
        update={() => {
          setEditedMenu(undefined);
          setNewMenuDate(undefined);
          return updateMenus();
        }}
        mealTypes={mealTypes}
      />
    </Modal>
  );

  const addMenuButton = (disabled: boolean, date: Date) => (
    <button
      onClick={() => onAddMenu(date)}
      type="button"
      className="add-menu-button"
      disabled={disabled}
    >
      <div className="large-text" style={{ width: '100%' }}>
        +
      </div>
    </button>
  );

  return (
    <>
      {(editedMenu || newMenuDate) && menuEditorModal}
      <div className="edit-menus-page">
        <div style={{ marginRight: '20px' }}>
          <Dishes onUpdate={() => getDishes()} dishes={dishes} />
        </div>
        <div
          className="menus-top-table"
          style={{
            gridTemplateColumns: `repeat(${dates.current.length}, 1fr)`,
          }}
        >
          <div className="menus-header-row">{`${getMonthDisplayName(
            dates.current,
          )} ${todayDate.current.getFullYear()}`}</div>
          {dates.current.map((date, index) => {
            const matchingMenus = filterMenusForDate(menus, date);
            const allMenusExist = areAllMenusIncluded(
              matchingMenus,
              restaurants,
            );
            return (
              <div
                key={date.toString()}
                className="day-column"
                style={{ gridColumn: `${index + 1} / ${index + 1}` }}
              >
                <div className="day-column-header">
                  <div className="date-name">{getWeekdayName(date)}</div>
                  <div className="date-number">{date.getDate()}</div>
                </div>
                {addMenuButton(allMenusExist, date)}
                {matchingMenus?.map((menu) => (
                  <MenuItemView
                    key={menu.id}
                    restaurants={restaurants}
                    menu={menu}
                    onEditMenu={setEditedMenu}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
