export type LocalizedString = { he: string; en: string };

export type FoodPref = 'Vegetarian' | 'Vegan';
type Allergy = 'Lactose' | 'Gluten' | 'Peanuts';
type Gender = 'Male' | 'Female' | 'Other';
export type MealType = 'Breakfast' | 'Lunch' | 'Dinner';
export type DishType = 'Vegetarian' | 'Vegan';
export type RestaurantType = 'Standard' | 'Street Food';

export type Language = 'en' | 'he';

/** Type representing a user in the Panda app */
export type User = {
  /** email of user */
  email: string;
  /** first name */
  firstName: string;
  /** last name */
  lastName: string;
  /** true if the user is a restaurant worker */
  isRestaurantWorker: boolean;
  /** true if the user is a FrontDesk worker */
  isFrontDeskWorker: boolean;
  /** true if the user is a restaurant (when running on the tablet) */
  isTablet: boolean;
  /** id for the user */
  id: number;
  /** array of allergies associated with the user (can be empty) */
  allergies: Allergy[];
  /** array of food preferences associated with the user (can be empty) */
  foodPreferences: FoodPref[];
  /** User's gender (optional, can be null) */
  gender: Gender | null;
};

/** Type for Dish */
export type Dish = {
  id: number;
  name: LocalizedString;
  description: LocalizedString;
  type?: DishType;
  calories?: number;
};

/** Type for menu in specific day in specific restaurant */
export type Menu = {
  id: number;
  date: Date;
  dishes: Dish[];
  mealType: MealType;
  restaurantId: number;
  notes?: LocalizedString;
};

/** Type for a time which can be book in one meal type each day */
export type EatingRound = {
  id: number;
  mealType: MealType;
  startTime: Date;
  endTime: Date;
  numOfSeatsLeft: number;
};

/** Type for restaurant */
export type Restaurant = {
  id: number;
  name: string;
  description: string;
  type: RestaurantType;
  /** Rounds list in this restaurant and the seat that available today */
  eatingRounds: EatingRound[];
  /** if today this restaurant has menu for each meal */
  todayMenus: Record<MealType, boolean>;
};

/** Type for base of order when created */
export type OrderBase = {
  eatingRoundId: number;
  date: Date;
};

/** Type for Order with full details */
export type Order = OrderBase & {
  id: number;
  mealType: MealType;
  restaurantId: number;
};

/** Type for base of purchase when created */
export type PurchaseBase = {
  restaurantId: number;
  cardId: string;
  numberOfGuests: number;
};

/** Type for base of purchase when created */
export type ManualPurchaseBase = {
  restaurantId: number;
  numberOfGuests: number;
  userEmail: string
};

/** Type for base of purchase when created */
export type UserMadePurchase = {
  restaurantId: number;
  numberOfGuests: number;
};


export enum PurchaseStatus {
  successful = "successful",
  alreadyExists = "alreadyExists",
  userDoesNotExists = "userDoesNotExists",
  failed = "failed",
  noEatingRound = "noEatingRound",
  cardDoesNotExist = "cardDoesNotExist"
}

export type PurchaseResponse = {
  status: PurchaseStatus;
  userEmail?: string;
}

export type Card = {
  cardId: string;
  email: string;
  username: string;
};

