// eslint-disable-next-line import/no-mutable-exports

const config = {
  API_PATH: process.env.API_PATH ?? 'http://localhost:5000/',
  CSRF_TOKEN_COOKIE_NAME: 'panda-csrf',
  CSRF_TOKEN_HEADER_NAME: 'X-CSRFToken',
  SENTRY_DSN: process.env.SENTRY_DSN,
};

export default config;
