import React, { useState } from 'react';
import { Dish } from '../../common/types';
import DishesForMenuEditor from '../DishesForMenuEditor/DishesForMenuEditor';
import './AddDishes.scss';

type AddDishesProps = {
  dishes: Dish[];
  onAddDish: (dish: Dish) => void;
};

export default function AddDishes(props: AddDishesProps) {
  const { dishes, onAddDish } = props;
  const [displayDishes, setDisplayDishes] = useState(false);
  const visibleStyle = displayDishes ? ' visible' : ' hidden';
  const buttonStyle = displayDishes ? ' active-button' : '';
  return (
    <>
      <button
        type="button"
        className={`add-dish-menu-button${buttonStyle}`}
        onClick={() => setDisplayDishes(!displayDishes)}
      >
        Add Dish
      </button>
      <div className={`dishes-for-add-container${visibleStyle}`}>
        <DishesForMenuEditor
          dishes={dishes}
          title=""
          onDishClicked={onAddDish}
        />
      </div>
    </>
  );
}
