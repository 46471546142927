import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTimeDisplay } from '../../common/dateUtils';
import {
  getLastStartTimeRoundPerMeal,
  isTimePassedGracePeriod,
} from '../../common/timeUtils';
import { MealType, Order, Restaurant } from '../../common/types';
import './RestaurantCardView.scss';
import BookedSvg from '../../assets/booked.svg';
import HIconSvg from '../../assets/H.svg';
import BIconSvg from '../../assets/B.svg';

function getRestaurantIcon(restaurantName: string) {
  const firstLetter = restaurantName.charAt(0);
  if (firstLetter === 'H') {
    return <HIconSvg className="restaurant-img-icon" />;
  }
  return <BIconSvg className="restaurant-img-icon" />;
}

interface RestaurantCardViewProps {
  restaurant: Restaurant;
  mealType: MealType;
  orderBooked?: Order;
}

/**
 * View for restaurant
 */
export default function RestaurantCardView(props: RestaurantCardViewProps) {
  const { restaurant, mealType, orderBooked } = props;

  const location = useLocation();
  let orderBookedStartTime;
  let orderBookedEndTime;
  if (orderBooked) {
    const bookedEatingRound = restaurant.eatingRounds.find(
      (eatingRound) => eatingRound.id === orderBooked.eatingRoundId,
    );
    orderBookedStartTime = getTimeDisplay(bookedEatingRound.startTime);
    orderBookedEndTime = getTimeDisplay(bookedEatingRound.endTime);
  }

  const lastStartRoundTime = getLastStartTimeRoundPerMeal(restaurant, mealType);
  const isTimePassed = isTimePassedGracePeriod(lastStartRoundTime);

  const getQuickBook = () => {
    if (isTimePassed) {
      return null;
    }
    return orderBooked ? (
      <Link
        to={{
          pathname: location.pathname,
          state: {
            displayRoundModal: { orderId: orderBooked.id },
          },
        }}
        className="secondary-primary-button"
      >
        Edit Booking
      </Link>
    ) : (
      <Link
        to={{
          pathname: location.pathname,
          state: {
            displayRoundModal: { restaurantId: restaurant.id, mealType },
          },
        }}
        className="action-primary-button"
      >
        Quick Book
      </Link>
    );
  };

  return (
    <div className={`restaurant ${orderBooked ? 'booked' : ''}`}>
      {getRestaurantIcon(restaurant.name)}
      <div className="details">
        {orderBookedStartTime && orderBookedEndTime && (
          <div className="booked-times">
            <BookedSvg className="booked-times-icon" height="10px" />
            {`BOOKED: ${orderBookedStartTime} - ${orderBookedEndTime}`}
          </div>
        )}
        <div className="title">{restaurant.name}</div>
        <div className="description">{restaurant.description}</div>
        <div className="action-buttons">
          {getQuickBook()}
          <Link
            to={`/restaurant/${restaurant.id}?meal=${mealType}`}
            className="view-menu-button secondary-primary-button"
          >
            View Menu
          </Link>
        </div>
      </div>
    </div>
  );
}
