import React from 'react';
import { Dish } from '../../common/types';
import 'react-toastify/dist/ReactToastify.css';

import './DishesForMenuEditor.scss';

import '../DishesList/DishesList.scss';

type DishesForMenuEditorProps = {
  dishes: Dish[];
  title: string;
  /** Action to invoke when the user clicks a certain dish (optional) */
  onDishClicked?: (dish: Dish) => void;
  onRemoveFromMenu?: (dish: Dish) => void;
};

/**
 * View for edit menus page
 */
export default function DishesForMenuEditor({
  dishes,
  title,
  onDishClicked,
  onRemoveFromMenu,
}: DishesForMenuEditorProps) {
  return (
    <div className="dishes-top-container">
      <div className="title-text">{title}</div>
      {dishes.length !== 0 && (
        <div className="dishes-container">
          {dishes.map((dish) => (
            <div key={dish.id} className="dish-item-row">
              <div
                style={onDishClicked ? { cursor: 'pointer' } : {}}
                onClick={onDishClicked ? () => onDishClicked(dish) : undefined}
                className="dish-item"
              >
                <div className="dish-name-text">
                  {dish.name.en} | {dish.name.he}
                </div>
                <div dir="rtl" className="dish-description-text">
                  {dish.description.he}
                </div>
                <div className="dish-description-text">
                  {dish.description.en}
                </div>
              </div>
              {onRemoveFromMenu && (
                <button
                  onClick={() => onRemoveFromMenu(dish)}
                  type="button"
                  className="remove-dish-button"
                >
                  Delete
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
